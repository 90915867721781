<template>
  <div>
    <loader v-if="isLoading"></loader>
    <section class="main-view">
      <b-notification v-if="existingKeys && existingKeys.length == 0" :closable="false">
            You have not uploaded a public key yet. You can do this by using the buttons below.
      </b-notification>

      <div class v-if="existingKeys && existingKeys.length > 0">
        <b-table
          ref="table"
          class="table-row"
          detailed
          :data="existingKeys"
        >
            <b-table-column
              field="key"
              current-sort
              label="PGP Key"
              sortable
              v-slot="props"
            >{{ props.row.key.substring(1,100) + '...' }}</b-table-column>

          <template slot="detail" slot-scope="props">
                <pre>{{props.row.key }}</pre>
            </template>
        </b-table>
      </div>

      <h4>Upload your public armored PGP key</h4>
      <b-field>
        <b-upload v-model="publicKeyFile" drag-drop accept=".txt, .pub, .asc">
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"></b-icon>
              </p>
              <p>Drop your key here or click to upload</p>
            </div>
          </section>
        </b-upload>
      </b-field>

      <div class="tags">
        <span v-if="publicKeyFile" class="tag is-primary">
          {{publicKeyFile.name}}
          <button
            class="delete is-small"
            type="button"
            @click="deleteDropFile(index)"
          ></button>
        </span>
      </div>
      <button
        type="submit"
        aria-label="Create Inbound Adapter"
        class="button is-primary"
        @click="uploadFiles()"
      >
        <span class="icon is-small">
          <i class="mdi mdi-plus-box"></i>
        </span>
        <span>Upload</span>
      </button>&nbsp;&nbsp;
      <button
        type="button"
        id="cancel-button"
        aria-label="Cancel Create Inbound Adapter"
        @click="cancelEdit"
        class="button is-danger"
      >
        <span class="icon is-small">
          <i class="mdi mdi-cancel"></i>
        </span>
        <span>Cancel</span>
      </button>&nbsp;&nbsp;
      <button
        type="button"
        id="generate-keys-button"
        @click="generatePgpKeys"
        class="button is-success"
      >
        <span class="icon is-small">
          <i class="mdi mdi-key-plus"></i>
        </span>
        <span>Generate PGP Keys</span>
      </button>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      existingKeys: null,
      isLoading: false,
      publicKeyFile: null,
    };
  },
  computed: {
    isFormDirty() {
      return !!this.publicKeyFile;
    },
  },
  mounted() {
    this.dataService = this.$dataService(this.$route.params.tenant);
    this.loadAsyncData();
  },
  methods: {
    cancelEdit() {
      if (this.isFormDirty) {
        this.$buefy.dialog.confirm({
          title: 'Confirm cancel',
          message: 'You have unsaved changes, do you want to cancel?',
          confirmText: 'Yes',
          cancelText: 'No',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.$router.push({ name: 'landing' });
          },
        });
      } else {
        this.$router.push({ name: 'landing' });
      }
    },
    deleteDropFile() {
      this.publicKeyFile = null;
    },
    uploadFiles() {
      const formData = new FormData();
      formData.append(this.publicKeyFile.name, this.publicKeyFile);
      this.dataService.publicKeys
        .uploadKeys(formData)
        .then(({ data }) => {
          this.$buefy.toast.open(`Key uploaded! Fingerprint : ${data.fingerprint}`);
          this.loadAsyncData();
        })
        .catch((error) => {
          this.$buefy.toast.open(
            `Failed to upload key. ${error.response.data.message}`,
          );
          throw error;
        });
    },
    generatePgpKeys() {
      this.$router.push({ name: 'generateKeys' });
    },
    async loadAsyncData() {
      this.isLoading = true;
      this.dataService.publicKeys
        .getKeys()
        .then((result) => {
          const { data } = result;

          if (data.keys) {
            this.existingKeys = data.keys.map(x => ({ key: Buffer.from(x.key, 'base64').toString('utf8') }));
          } else {
            this.existingKeys = [];
          }
          this.isLoading = false;
        })
        .catch((error) => /* istanbul ignore next */ {
          this.isLoading = false;
          throw error;
        });
    },
  },
};
</script>
